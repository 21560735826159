import React, { useState } from "react";

export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsMenuOpen(false);
  };

  return (
    <nav id="menu" className={`navbar navbar-default ${isMenuOpen ? 'navbar-open' : ''}`}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            AIDSS
          </a>
        </div>

        <div
          className={`collapse navbar-collapse ${isMenuOpen ? 'in' : ''}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li><a href="#features" className="page-scroll" onClick={toggleMenu}>Features</a></li>
            <li><a href="#about" className="page-scroll" onClick={toggleMenu}>About</a></li>
            <li><a href="#services" className="page-scroll" onClick={toggleMenu}>Initiatives</a></li>
            <li><a href="#portfolio" className="page-scroll" onClick={toggleMenu}>Gallery</a></li>
            <li><a href="#testimonials" className="page-scroll" onClick={toggleMenu}>Blog</a></li>
            <li><a href="#team" className="page-scroll" onClick={toggleMenu}>Team</a></li>
            <li><a href="#contact" className="page-scroll" onClick={toggleMenu}>Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
